import { reactive, ref } from "vue";
import { ImportGoods, ImportAllData } from "@/api/GoodsApi";
import CategoryService from "@/services/Goods/CategoryService";
import { EditGoodsAllArgs, productBatchImportBasicDTO } from "@/api/GoodsApi";
import { ElMessage } from "element-plus";
import { AfterSalesStatus, PriceStatus } from "@/common/enum";
export default function FormQuery(emit) {
    const afterSaleSettingRef = ref(null);
    // 自营销售模式下判断
    const validatePass = (rule, value, callback) => {
        // 自营模式开，验证通过
        if (value == AfterSalesStatus.Open) {
            callback();
            return;
        }
        // 分销模式开
        if (allImportGoods.data.settleStatus == PriceStatus.open) {
            callback();
            return;
        }
        callback(new Error('请选择销售模式'));
    };
    const validatePass1 = (rule, value, callback) => {
        if (!afterSaleSettingRef.value?.afterSaleValidate()) {
            callback(new Error('请填写正确的售后配置'));
        }
        else {
            callback();
        }
    };
    const rules = {
        productType: [
            { required: true, message: '请选择商品类型', trigger: 'change' },
        ],
        productCategoryId: [
            {
                required: true,
                message: '请选择商品分类',
                trigger: 'change',
            },
        ],
        selfSaleStatus: [{ required: true, validator: validatePass, trigger: 'change' }],
        afterSalesStatus: [{ required: true, validator: validatePass1, trigger: 'change' }],
        file: [
            {
                required: true,
                message: '请上传文件',
                trigger: 'change',
            },
        ],
    };
    // 售后设置组件ref
    const token = ref('');
    //商品分类
    const allTypes = reactive(new Array());
    const allData = reactive({ data: new EditGoodsAllArgs() });
    const allImportGoods = reactive({ data: new productBatchImportBasicDTO() });
    allImportGoods.data.settleStatus = PriceStatus.close;
    allImportGoods.data.selfSaleStatus = PriceStatus.close;
    const file = ref();
    // 批量导入所有商品弹窗
    const formDialogVisible = ref(false);
    const importGoodsForm = ref(null);
    allTypes.splice(0, allTypes.length);
    const cateResult = CategoryService.GetAllCatagory();
    cateResult.forEach((item) => {
        allTypes.push(item);
    });
    const handleSuccess = (files) => {
        allImportGoods.data.file = files;
    };
    const downloadTemplate = () => {
        window.open("/批量导入商品模板.xlsx");
    };
    //导入全部信息
    const importAllInfo = async (settleStatus, selfSaleStatus) => {
        allImportGoods.data.afterSalesStatus = afterSaleSettingRef.value.productAfterSalesDTO.afterSalesStatus;
        allImportGoods.data.productAfterSalesLabelDTO = { ...afterSaleSettingRef.value.productAfterSalesDTO };
        // 校验基础信息+file 
        // 提交基础信息 =》 token
        // 提交token+ffile
        importGoodsForm.value.validate((valid) => {
            if (valid) {
                console.log('submit!');
            }
            else {
                console.log('error submit!');
                return false;
            }
        });
        const token = await importBasicInfo();
        const formdata = new FormData();
        formdata.append('file', allImportGoods.data.file);
        formdata.append('token', token);
        const res = await ImportAllData(formdata);
        if (res.isSuccess) {
            ElMessage.success({
                message: "导入完成",
                type: "success",
            });
            emit('close');
        }
        else {
            ElMessage.success({
                message: "保存失败，请稍候重试",
                type: "success",
            });
        }
        return res;
    };
    //导入基础信息
    const importBasicInfo = async () => {
        const valid = await importGoodsForm.value.validate();
        if (valid) {
            const res = await ImportGoods(allImportGoods.data);
            if (res.isSuccess) {
                const token = res.resultData;
                return token;
            }
            ElMessage.success({
                message: "保存失败，请稍候重试",
                type: "success",
            });
        }
        else {
            return false;
        }
    };
    const clearFile = () => {
        allImportGoods.data.file = '';
    };
    return {
        clearFile,
        allTypes,
        allData,
        downloadTemplate,
        allImportGoods,
        importGoodsForm,
        importBasicInfo,
        afterSaleSettingRef,
        token,
        importAllInfo,
        rules,
        handleSuccess,
        formDialogVisible,
    };
}
