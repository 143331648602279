import { AfterSalesStatus } from '@/common/enum';
import { defineComponent, watch } from 'vue';
import afterSaleSetting from './afterSaleSetting';
export default defineComponent({
    props: {
        productAfterSalesLabelDTO: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    setup(props) {
        const { afterSaleTags, unAfterSaleTags, afterSaleArr, productAfterSalesDTO, changeAfterSaleStatus, afterSaleValidate, changeItemStatus, } = afterSaleSetting(props);
        watch(afterSaleArr, () => {
            for (const key in afterSaleArr) {
                if (!afterSaleArr[key].status) {
                    afterSaleArr[key].desc = '';
                }
            }
        });
        return {
            afterSaleTags,
            unAfterSaleTags,
            productAfterSalesDTO,
            afterSaleArr,
            AfterSalesStatus,
            changeAfterSaleStatus,
            afterSaleValidate,
            changeItemStatus,
        };
    },
});
