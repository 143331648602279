import { AfterSalesStatus, AfterSaleType } from "@/common/enum";
import { reactive, watch } from "vue";
export default function afterSaleSetting(props) {
    const modeData = reactive({
        afterSalesStatus: AfterSalesStatus.Open,
        closeAfterSalesDesc: '',
        specialIdentification: {
            noPostage: {
                status: false,
                desc: '',
            },
            unsupportNoReaon: {
                status: false,
                desc: '',
            },
            partialRefund: {
                status: false,
                desc: '',
            },
            specialStatement: {
                status: false,
                desc: '',
            },
        },
    });
    const productAfterSalesDTO = reactive({ ...modeData });
    watch(() => props.productAfterSalesLabelDTO, () => {
        for (const key in props.productAfterSalesLabelDTO) {
            productAfterSalesDTO[key] = props.productAfterSalesLabelDTO[key];
        }
        productAfterSalesDTO.specialIdentification = reactive(props.productAfterSalesLabelDTO.specialIdentification);
    });
    const afterSaleTags = ['unsupportNoReaon', 'noPostage', 'partialRefund', 'specialStatement'];
    const unAfterSaleTags = ['noPostage', 'specialStatement'];
    const afterSaleArr = reactive({
        'noPostage': {
            title: AfterSaleType.noPostage,
            placeholder: '必填，填写补充说明100字以内',
            status: false,
            desc: '',
        },
        'unsupportNoReaon': {
            title: AfterSaleType.unsupportNoReaon,
            placeholder: '必填，填写补充说明100字以内',
            status: false,
            desc: '',
        },
        'partialRefund': {
            title: AfterSaleType.partialRefund,
            placeholder: '必填，填写补充说明100字以内，注明退款比例',
            status: false,
            desc: '',
        },
        'specialStatement': {
            title: AfterSaleType.specialStatement,
            placeholder: '必填，填写补充说明100字以内',
            status: false,
            desc: '',
        },
    });
    const changeAfterSaleStatus = () => {
        productAfterSalesDTO.closeAfterSalesDesc = '';
        for (const key in productAfterSalesDTO.specialIdentification) {
            const keyStr = key;
            productAfterSalesDTO.specialIdentification[keyStr].status = false;
            productAfterSalesDTO.specialIdentification[keyStr].desc = '';
        }
    };
    const afterSaleValidate = () => {
        if (productAfterSalesDTO.afterSalesStatus == AfterSalesStatus.Close && productAfterSalesDTO.closeAfterSalesDesc.length == 0) {
            return false;
        }
        for (const key in productAfterSalesDTO.specialIdentification) {
            const keyStr = key;
            if (productAfterSalesDTO.specialIdentification[keyStr].status && productAfterSalesDTO.specialIdentification[keyStr].desc.trim().length == 0) {
                return false;
            }
        }
        return true;
    };
    const changeItemStatus = (item) => {
        productAfterSalesDTO.specialIdentification[item].desc = '';
    };
    return {
        afterSaleTags,
        unAfterSaleTags,
        productAfterSalesDTO,
        afterSaleArr,
        changeAfterSaleStatus,
        afterSaleValidate,
        changeItemStatus,
    };
}
