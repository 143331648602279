import { reactive, ref } from "vue";
import { GetAllData, } from "@/api/GoodsApi";
import { ImportGoodsStatus } from "@/common/enum";
import { FormatterDateTime } from "@/common/formatter";
import { DownloadBatchImportList } from '@/api/DownloadApi';
export default function ImportQuery() {
    const importFormDlg = ref(false);
    const goSection = () => {
        importFormDlg.value = true;
    };
    const headerImportTime = ref("");
    const downloadTemplate = () => {
        window.open("/批量导入商品模板.xlsx");
    };
    const totalCount = ref(0);
    const changeTime = (val) => {
        params.startTime = val && FormatterDateTime(val[0], "yyyy-MM-dd hh:mm:ss") || "";
        params.endTime = val && FormatterDateTime(val[1], "yyyy-MM-dd hh:mm:ss") || "";
    };
    const allData = reactive(new Array());
    const params = reactive({
        endTime: '',
        startTime: '',
        status: ImportGoodsStatus.None,
        pageNum: 1,
        pageSize: 10,
    });
    const downResult = (row) => {
        DownloadBatchImportList({ id: row.id });
    };
    async function queryData() {
        const result = await GetAllData(params);
        if (result.isSuccess) {
            totalCount.value = result.resultData.total;
            allData.splice(0, allData.length);
            result.resultData.list.forEach((item) => {
                allData.push(item);
            });
        }
    }
    return {
        goSection,
        importFormDlg,
        downloadTemplate,
        headerImportTime,
        queryData,
        allData,
        params,
        downResult,
        totalCount,
        changeTime,
    };
}
